import React, { useEffect, useState } from "react";
import "./Wwe.css";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Button } from "../../components/Navbar/Navbar";
import logo from "../../components/Navbar/logo.svg";
//import { H } from '../Page2/Page2';
import { IoMdArrowRoundBack } from "react-icons/io";
import WideBox from "./components/WideBox";
import { useSelector } from "react-redux";
import { useWallet } from "use-wallet";
import L from "./Left.png";
import R from "./Right.png";
import Web3 from "web3";

const Sec = styled.section`
  min-height: 50rem;
  width: 100%;
  display: flex;
  padding: 8rem 0 4rem 0;
  // flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  @media only screen and (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Width = styled.div`
  width: 1300px;
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  @media only screen and (max-width: 1400px) {
    width: 95%;
  }
`;

const H = styled.h1`
  color: #000;
  font-size: 2rem;
  font-weight: light;
  margin-bottom: 2rem;
  text-align: left !important;
  @media only screen and (max-width: 768px) {
    font-weight: 510;
    font-size: 1.2rem;
    font-weight: bold;
  }
`;

const T = styled.p`
  color: #fff;
  font-weight: 500;
  font-size: 1.2rem;
  margin-bottom: 0.75rem;
  text-align: center;
  //width: 20rem;

  @media only screen and (max-width: 768px) {
    font-weight: 510;
    font-size: 1rem;
  }
`;

const LeftImg = styled.div`
  position: absolute;
  z-index: 1;
  left: 0;
  height: 23rem;
  width: 15rem;

  @media only screen and (max-width: 1100px) {
  }
`;

const RightImg = styled.div`
  position: absolute;
  z-index: 1;
  right: 0;
  height: 25rem;
  width: 15rem;

  @media only screen and (max-width: 1100px) {
  }
`;

const Right = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  // align-items: center;
  width: 768px;
  //height: 30rem;
  padding: 3rem 0;
  border-radius: 1rem;
  z-index: 2;
  background: linear-gradient(to right, #061212, #384f84);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

  @media only screen and (max-width: 1100px) {
    width: 95%;
    margin: 3rem 0 0 0;
  }
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.4);
  margin: 1rem 0 0 0;
`;
const Margin = styled.div`
  margin: 4rem 0 0 0;

  @media only screen and (max-width: 1100px) {
    margin: 3rem 0 0 0;
  }
`;
const MarginLeft = styled.div`
  //margin: 0 2rem 0 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //width: 100%;

  @media only screen and (max-width: 1100px) {
    margin: 0 1rem;
  }
`;

const Flex = styled.div`
  display: flex;

  width: 100%;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;
const Content = styled.div`
  width: 65%;

  @media only screen and (max-width: 768px) {
    width: 85%;
  }
`;

const FourmPage4 = () => {
  const contract = useSelector((state) => state.contract);

  const wallet = useWallet();
  const [donatedAmount, setdonatedAmount] = useState();
  const [tokenBought, settokenBought] = useState();
  const [priceInUSD, setPriceInUSD] = useState();
  const [priceInETH, setPriceInETH] = useState();





  const [usdInput, setUsdInput] = useState(0);
  const [accrtInput, setAccrtInput] = useState(0);
  const [ethInput, setEthInput] = useState(0);

  const [miniumBuyLimit,setMiniumBuyLimit]= useState();

 
  const handleParticipate = async () => {
   
    const ethToAccrt =ethInput/(priceInETH/1e18);
    const accrtAmount = accrtInput
   
    if(!ethToAccrt){
      alert("Please Enter ETH Amount")

      return
    }


    if(!accrtAmount){
      alert("Please Enter Accredited Amount")

      return
    }


    if(accrtAmount == miniumBuyLimit || accrtAmount > miniumBuyLimit ){
      const tx = await contract.methods.participate().send({
        from: wallet.account,
        value: Web3.utils.toWei(ethInput),
      });
      console.log(tx);
      if (tx.status) {
        alert("Transaction Successfull");
      }
    }else{
      alert(`Minimum Buy Limit is ${miniumBuyLimit} Accredited Tokens`)
      return
    }


   
  };

  const loadData = async(account)=>{
    if(account){

      let amount = await contract.methods.contributions(account).call();
      let token = await contract.methods.tokenBought(account).call();
      settokenBought(token);
      setdonatedAmount(amount);
      console.log(amount);

    }

    let tokenPriceInETH = await contract.methods.tokenPriceInETH().call();
    let tokenPriceInUSD = await contract.methods.pricePerTokenInUSD().call();

    let minBuyLimit = await contract.methods.minBuyAmount().call();
    setPriceInETH(tokenPriceInETH);
    setPriceInUSD(tokenPriceInUSD);

    setMiniumBuyLimit(minBuyLimit/1e18)
    // setprice(price);
   
  }

  useEffect(async () => {
    if(contract){
     await loadData()
    }
    // if (contract && wallet.status == "connected") {
    //   let amount = await contract.methods.contributions(wallet.account).call();
    //   let token = await contract.methods.tokenBought(wallet.account).call();
    //   // let price = await contract.methods.pricePerToken().call();

    //   let tokenPriceInETH = await contract.methods.tokenPriceInETH().call();
    //   let tokenPriceInUSD = await contract.methods.pricePerTokenInUSD().call();

    //   let minBuyLimit = await contract.methods.minBuyAmount().call();
    //   setPriceInETH(tokenPriceInETH);
    //   setPriceInUSD(tokenPriceInUSD);

    //   setMiniumBuyLimit(minBuyLimit/1e18)
    //   console.log(amount);
    //   // setprice(price);
    //   settokenBought(token);
    //   setdonatedAmount(amount);
    // }

    return () => {};
  }, [contract, wallet.status, wallet.account]);

  const renderButton = () => {
    if (wallet.status === "connected") {
      return (
        <Button
          style={{
            width: "10rem",
            borderRadius: "1rem",
            margin: "0 2rem 0 0",
            color: "#fff",
            border: "1px solid #FFF",
          }}
          onClick={() => {
            handleParticipate();
          }}
        >
          Buy
        </Button>
      );
    } else {
      return (
        <Button
          style={{
            width: "15rem",
            borderRadius: "1rem",
            margin: "0 2rem 0 0",
            color: "#fff",
            border: "1px solid #FFF",
          }}
          onClick={() => {
            wallet.connect();
          }}
        >
          Connect Wallet
        </Button>
      );
    }
  };



  return (
    <Sec>
      <Width>
        <LeftImg>
          <img src={L} alt="" style={{ width: "100%", height: "100%" }} />
        </LeftImg>
        <RightImg>
          <img src={R} alt="" style={{ width: "100%", height: "100%" }} />
        </RightImg>
        <Right>
          <MarginLeft>
            {/* <T
            style={{
              margin: "0 0 0 0",
              fontSize: "1.6rem",
              color: "rgba(0,0,0,0.9)",
            }}
          >
            Name your fundraiser
          </T>

          <input
            type="Text"
            placeholder="Donate to help"
            className="input1"
            style={{
              height: "3.75rem",
              width: "10rem",
              border: "1px solid rgba(0,0,0,0.2)",
              borderRadius: "0.75rem",
              width: "60%",
              margin: "2rem 0 0 0",
            }}
          /> */}

            <Content style={{}}>
              <T
                style={{
                  margin: "0 0 0 0",
                  fontSize: "1.6rem",
                  color: "rgba(255,255,255,0.9)",
                }}
              >
                Participate in Accredited Presale
              </T>

              <div style={{ margin: "1.5rem 0 0 0" }}>
                <div style={{ color: "#fff", margin: "0 0 0 0.5rem" }}>
                  Accredited Amount
                </div>
                <input
                  type="number"
                  placeholder="Enter Accredited Amount"
                  className="input1"
                  value={accrtInput}
                  onChange={(e)=>{
                    setAccrtInput(e.target.value);
                    const totalPriceInUSD = Number(e.target.value)*(priceInUSD/1e18);
                    setUsdInput(totalPriceInUSD.toFixed(3))


                    const totalPriceInETH= Number(e.target.value)*(priceInETH/1e18);
                    setEthInput(totalPriceInETH.toFixed(6))
                  }}
                  style={{
                    height: "4rem",
                    width: "10rem",
                    border: "1px solid rgba(0,0,0,0.2)",
                    borderRadius: "0.75rem",
                    width: "100%",
                    paddingLeft: "10px",
                    margin: "0.5rem 0 0 0",
                  }}
                  
                />
              </div>

              <div style={{ margin: "1.5rem 0 0 0" }}>
                <div style={{ color: "#fff", margin: "0 0 0 0.5rem" }}>
                  USD Amount
                </div>
                <input
                  type="number"
                  placeholder="Enter USD Amount"
                  className="input1"
                  style={{
                    height: "4rem",
                    width: "10rem",
                    border: "1px solid rgba(0,0,0,0.2)",
                    borderRadius: "0.75rem",
                    width: "100%",
                    paddingLeft: "10px",
                    margin: "0.5rem 0 0 0",
                  }}
                  value={usdInput}
                  onChange={(e)=>{
                    setUsdInput(e.target.value)

                    const usdToAccrd =e.target.value/(priceInUSD/1e18);
                    setAccrtInput(usdToAccrd.toFixed(3));


                    const usdToETH= (usdToAccrd/1e18)*(priceInETH);
                    setEthInput(usdToETH.toFixed(6))
                  }}
                />
              </div>

              <div style={{ margin: "1.5rem 0 0 0" }}>
                <div style={{ color: "#fff", margin: "0 0 0 0.5rem" }}>
                  ETH Amount
                </div>
                <input
                  type="number"
                  placeholder="Enter ETH Amount"
                 

                  value={ethInput}
                  onChange={(e)=>{
                    setEthInput(e.target.value) 

                    const ethToAccrt =e.target.value/(priceInETH/1e18);
                    setAccrtInput(ethToAccrt.toFixed(3));

                    const _priceInUSD= (ethToAccrt/1e18)*(priceInUSD);
                    setUsdInput(_priceInUSD.toFixed(3))

                  }}
                  className="input1"
                  style={{
                    height: "4rem",
                    width: "15rem",
                    border: "1px solid rgba(0,0,0,0.2)",
                    borderRadius: "0.75rem",
                    width: "100%",
                    paddingLeft: "10px",
                    margin: "0.5rem 0 0 0",
                  }}
                />
              </div>

              <div style={{ margin: "2rem 0 0 0" }}>
                {priceInUSD && (
                  <Flex>
                    <T>Price Per Token (USD)</T>{" "}
                    <T>$ {(priceInUSD / 1e18).toFixed(2)}</T>
                  </Flex>
                )}

                {priceInETH && (
                  <Flex>
                    <T>Price Per Token (ETH)</T>{" "}
                    <T>{(priceInETH / 1e18).toFixed(6)}</T>
                  </Flex>
                )}

                {donatedAmount && (
                  <Flex>
                    <T>Your Contribution</T>{" "}
                    <T>{(donatedAmount / 1e18).toFixed(4)}</T>
                  </Flex>
                )}
                {/* <Flex>
                  <T>Your Reedamble</T> <T>XX</T>
                </Flex> */}
                {tokenBought && (
                  <Flex>
                    <T>Token Bought</T> <T>{(tokenBought / 1e18).toFixed(4)}</T>
                  </Flex>
                )}


            {miniumBuyLimit && (
                  <Flex>
                    <T>Minimum Accredited Buy Limit</T> <T>{(miniumBuyLimit).toFixed(4)}</T>
                  </Flex>
                )}
              </div>
            </Content>
          </MarginLeft>

          <div>
            <Line />
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "2rem 0 0 0",
              }}
            >
              {/* <IoMdArrowRoundBack
              style={{ margin: "0 0 0 2rem", fontSize: "2rem" }}
            /> */}
              {/* <Button
              style={{
                width: "10rem",
                borderRadius: "1rem",
                margin: "0 2rem 0 0",
              }}
            >
              Donate
            </Button> */}
              {renderButton()}
            </div>
          </div>
        </Right>
      </Width>
    </Sec>
  );
};

export default FourmPage4;
