import React, { useEffect, useState } from "react";
import "./App.css";
import { useWallet } from "use-wallet";
import Navbar from "./components/Navbar/Navbar";
import Home from "./Page/Page1/Home";
import Page2 from "./Page/Page2/Page2";
import Page3 from "./Page/Page3/Page3";
import Page3a from "./Page/Page3a/Page3a";
import Page4 from "./Page/Page4/Page4";
import Page5 from "./Page/Page5/Page5";
import Page6 from "./Page/Page6/Page6";
import Page6a from "./Page/Page6a/Page6a";
import Page6b from "./Page/Page6b/Page6b";
import Page7 from "./Page/Page7/Page7";
import Footer from "./Page/Foot/Footer";
import Fourm from "./Page/Fourm/Fourm";
import { Routes, Route } from "react-router-dom";
import FourmPage1 from "./Page/Fourm/FourmPage1";
import FourmPage2 from "./Page/Fourm/FourmPage2";
import FourmPage3 from "./Page/Fourm/FourmPage3";
import FourmPage4 from "./Page/Fourm/FourmPage4";
import BlockchainProvider from "./BlockchainProvider";
import Donate from "./Page/Donate";

import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import passwords from "./BlockchainProvider/passwords.json";

const App = () => {
  const wallet = useWallet();
  const [isLoginDone, setLoginDone] = useState(false);
  const [password, setPassword] = useState();
  const [isPasswordInCorrect,setPasswordInCorrect] = useState(false)
  useEffect(() => {
    wallet.connect();
  }, []);

  const handleLogin = () => {
    let isFound = false;
    for (let _password of passwords) {
      if (password.toLowerCase() === _password.toLowerCase()) {
        isFound = true;
        break;
      }
    }

    setPasswordInCorrect(!isFound);
    setLoginDone(isFound);
  };
  const renderPopUp = () => {
    return (
      <Popup
        open={true}
        closeOnEscape={false}
        closeOnDocumentClick={false}
        // trigger={<button className="button"> Open Modal </button>}
        modal
        nested
      >
        {(close) => (
          <div className="modal">
            <button className="close" onClick={close}>
              &times;
            </button>
            <div className="header">Enter Referral Code</div>
            <div className="content">Please Enter Referral Code To Proceed</div>

            <div className="content">
              <input
                type="password"
                value={password}
                style={{ fontSize: 22 }}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </div>

            <div className="actions">
              <button
                onClick={() => {
                  handleLogin();
                }}
              >
                Proceed
              </button>
            </div>

           {isPasswordInCorrect? <div className="content">
           <div className="header" style={{color:"red"}}>Incorrect Password</div>

            </div>:null}
            
          </div>
        )}
      </Popup>
    );
  };
  useEffect(() => {
    // showLoginPrompt();
  }, []);

  return (
    <>
      <BlockchainProvider />
      <Navbar />
      <Routes>
        <Route
          path="/"
          element={
            isLoginDone ? (
              <>
                <FourmPage4 />
                <Page3a />
                <Page2 />
                <Page7 />
                <Page4 />
                <Page3 />
                <Footer />
              </>
            ) : (
              renderPopUp()
            )
          }
        />

        {/* <Route path="/earn" element={<Page4 big />} />
        <Route
          path="/donate"
          element={
            <>
              <Donate />
              <Footer without/>
            </>
          }
        /> */}
      </Routes>
    </>
  );
};

export default App;
